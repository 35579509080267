<template>
  <div class="promotion">
    <NavBar/>
    <h1>This is an promotion page!</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar
    
  }
}
</script>

<style>
</style>
