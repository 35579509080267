<template>
  <div class="self-banking">
    <NavBar/>
    <h1>This is an self-banking page!</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar
    
  }
}
</script>

<style>
</style>
