<template>
  <div class="LS-Wallet">
    <NavBar />
    <div class="header">
      <div class="card_promote">
        <div class="title_header">
          <h1>
            Explore our diverse <br />
            range of card products
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.
          </p>
          <div class="btn_top">
            <button class="btn_get">Get your card</button>
            <button class="btn_contact">Contact Us</button>
            <button class="edit"><img src="@/assets/image/row_up.png" alt="edit"></button>
          </div>
        </div>
        <div class="image-content">
          <img
            class="ellipse"
            src="@/assets/ls_wallet/Ellipse.png"
            alt="Background Ellipse"
          />
          <img
            class="cards"
            src="@/assets/ls_wallet/cards.png"
            alt="Card products"
          />
        </div>
      </div>
      <div class="vector_style">
        <img src="@/assets/image/Vector.png" alt="vector" />
      </div>
      <div class="header_bot">
        <img src="@/assets/ls_wallet/unionPay.png" alt="union" />
        <img src="@/assets/ls_wallet/visa.png" alt="visa" />
        <img src="@/assets/ls_wallet/Mastercard.png" alt="mastercard" />
        <img src="@/assets/ls_wallet/applePay.png" alt="applePay" />
        <img src="@/assets/ls_wallet/gPay.png" alt="gPay" />
        <img src="@/assets/ls_wallet/Paypal.png" alt="Paypal" />
        <img src="@/assets/ls_wallet/stripe.png" alt="stripe" />
      </div>
    </div>
    <div class="container">
      <div class="banking-app">
        <div class="bank_content_left">
          <h1>Our Services</h1>
          <div class="bank_card_group">
            <div class="bank_card1">
              <img src="@/assets/icons/instant.png" alt="" />
              <h5>
                E-Commerct App Development <br />
                Service
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
            <div class="bank_card2">
              <img src="@/assets/icons/save.png" alt="" />
              <h5>
                Mobile Application <br />
                Development Sservice
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
            <div class="bank_card3">
              <img src="@/assets/icons/mobile.png" alt="" />
              <h5>
                Web Application <br />
                Development Service
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
            <div class="bank_card4">
              <img src="@/assets/icons/rank.png" alt="" />
              <h5>
                Professionan Web Design <br />
                Service
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
            <div class="bank_card5">
              <img src="@/assets/icons/virtual_card.png" alt="" />
              <h5>
                Virtual <br />
                cards
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
            <div class="bank_card6">
              <img src="@/assets/icons/contactless.png" alt="" />
              <h5>
                Contactless <br />
                Payments
              </h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim
              </p>
            </div>
          </div>
        </div>
        <div class="bank_content_right">
          <img src="@/assets/ls_wallet/phone.png" alt="Banking App on Phone" />
        </div>
      </div>
      <div class="rank_product">
        <div class="rank_product_left" id="rank_product_left">
          <a href="#rank_product_left">Overview</a>
          <a href="#rank_product_left">Premium Credit card</a>
          <a href="#rank_product_left">SecurePlus Debit Mastercard</a>
          <a href="#rank_product_left">BudgetXpress Prepaid Visa</a>
          <a href="#rank_product_left">Premium Credit card</a>
          <a href="#rank_product_left">SecurePlus Debit Mastercard</a>
          <a href="#rank_product_left">BudgetXpress Prepaid Visa</a>
        </div>
        <div class="rank_product_right">
          <div class="rank_product_content">
            <h1>
              Explore our diverse range <br />
              of card products
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div class="product_content_img">
              <img
                src="@/assets/ls_wallet/product_card.png"
                alt="Ranked Product"
              />
            </div>
          </div>
          <div class="rank_product_list">
            <div class="rank_product_item">
              <h3>Perfect card for your needs</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div class="premium_cards">
              <div class="premium_card1">
                <img src="@/assets/ls_wallet/premium1.png" alt="Premium Card" />
              </div>
              <div class="premium_card2">
                <img src="@/assets/ls_wallet/premium2.png" alt="Premium Card" />
              </div>
              <div class="premium_card3">
                <img src="@/assets/ls_wallet/premium3.png" alt="Premium Card" />
              </div>
            </div>
            <button class="get_card">Get your card</button>
          </div>
        </div>
      </div>
    </div>
    <FooTer></FooTer>
  </div>
</template>

<script>
import FooTer from "../components/FooTer";
import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    FooTer,
    NavBar,
  },
  mounted() {
    const links = document.querySelectorAll(".rank_product_left a");

    links.forEach((link) => {
      link.addEventListener("click", function () {
        links.forEach((el) => el.classList.remove("active"));
        this.classList.add("active");
      });
    });
  },
};
</script>

<style scoped>
/* ============Header============ */
.LS-Wallet {
  padding-top: 40px;
  background-color: #f8f7f5;
  position: relative;
  z-index: 1;
}

.vector_style img {
  position: absolute;
  right: 0%;
  top: 52%;
  width: 8%;
  height: 250px;
  z-index: 2;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  z-index: 3;
}

.card_promote {
  margin: 2% 8% 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #260b70;
  color: #fff;
  border-radius: 20px;
  padding: 40px;
  height: 600px;
  overflow: hidden;
  position: relative;
  z-index: 4; /* Ensure it is above vector image */
}

.title_header {
  max-width: 50%;
  padding: 0 10%;
}

.title_header h1 {
  font-size: 3rem;
  width: 500px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.title_header p {
  font-size: 1rem;
  margin-bottom: 30px;
  width: 500px;
  line-height: 1.5;
}

.btn_top {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  width: 400px;
}
.btn_top > .btn_get {
  background-color: #5761e5;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
}
.btn_top > .btn_contact {
  background-color: transparent;
  color: #fff;
  border: 1px solid #0884d1;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
}
.edit{
  width: 50px;
  height: 50px;
  padding: 15px;
  border-radius: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.edit img{
  width: 20px;
  height: 20px;  
  cursor: pointer;
}
.image-content {
  position: relative;
  max-width: 50%;
  display: flex;
  align-items: flex-start;
}

.image-content .ellipse {
  position: absolute;
  left: 15%;
  top: 40%;
  width: 85%;
  height: 330px;
}

.image-content .cards {
  position: relative;
  z-index: 1;
  width: 80%;
  height: auto;
  margin-top: -55px;
  margin-left: 27.5%;
}

.card_promote::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 50%, #07084d 100%);
  z-index: 0;
  transform: translateX(-50%);
  border-radius: 20px;
}

/* cards */
.header_bot {
  display: flex;
  width: 100%;
  gap: 9.7rem;
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: rgb(8, 19, 116);
  cursor: pointer;
  z-index: 1;
  transform: translateY(-100%);
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.header_bot::-webkit-scrollbar {
  display: none;
}

.header_bot img {
  width: 80px;
  height: 50px;
  cursor: pointer;
  flex-shrink: 0;
}
/* end cards */
/* end Header */

/* ============Container============ */
/* App banking */
.banking-app {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.bank_content_left {
  max-width: 50%;
}

.bank_content_left h1 {
  line-height: 1.2;
  margin-bottom: 20px;
}

.bank_card_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 700px;
  margin-top: 20%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  gap: 20px;
  padding: 5px;
}

.bank_card1,
.bank_card2,
.bank_card3,
.bank_card4,
.bank_card5,
.bank_card6 {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  text-align: left;
}
.bank_card1 img,
.bank_card2 img,
.bank_card3 img,
.bank_card4 img,
.bank_card5 img,
.bank_card6 img {
  width: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}
.bank_card1 img:hover,
.bank_card2 img:hover,
.bank_card3 img:hover,
.bank_card4 img:hover,
.bank_card5 img:hover,
.bank_card6 img:hover {
  filter: brightness(0.7);
  transition: filter 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
}

.bank_card1 h6,
.bank_card2 h6,
.bank_card3 h6,
.bank_card4 h6,
.bank_card5 h6,
.bank_card6 h6 {
  margin-bottom: 10px;
}

.bank_card1 p,
.bank_card2 p,
.bank_card3 p,
.bank_card4 p,
.bank_card5 p,
.bank_card6 p {
  font-size: 0.9rem;
  color: #737373;
}

.bank_content_right {
  max-width: 40%;
}

.bank_content_right img {
  margin-top: 26%;
  width: 100%;
  height: auto;
}
/* end App banking */

/* rank_product */
.rank_product {
  display: flex;
  margin-top: 5%;
  width: 100%;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 20px;
}

.rank_product_left {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 5%;
}

.rank_product_left a {
  color: #858282;
  margin-bottom: 20px;
  text-decoration: none;
}

.rank_product_left a.active {
  color: #4b00ed;
}

.rank_product_right {
  margin-top: 6%;
  width: 80%;
}

.rank_product_content {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.rank_product_content > p {
  width: 80%;
}

.product_content_img > img {
  width: 93%;
  border-radius: 20px;
  height: 350px;
}

.rank_product_list {
  margin-top: 7%;
  width: 100%;
  /* margin-left: -10%; */
  display: flex;
  flex-direction: column;
}

.rank_product_item {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;
}
.premium_cards {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.premium_card1,
.premium_card2,
.premium_card3 {
  position: absolute;
  width: 60%;
  height: 400px;
}

.premium_card1 {
  top: 0;
}

.premium_card2 {
  top: 60px;
}

.premium_card3 {
  top: 125px;
}

.premium_card1 img,
.premium_card2 img,
.premium_card3 img {
  width: 100%;
  height: 350px;
  border-radius: 20px;
}
.get_card {
  border-radius: 50px;
  background-color: #3f05bc;
  color: #fff;
  text-align: center;
  justify-content: center;
  margin-left: 21%;
  margin-top: -80px;
  margin-bottom: 50px;
  border: none;
  width: 150px;
  padding: 10px;
}
/* end rank_product */
/* Hover items */
.premium_card1 img:hover,
.premium_card2 img:hover,
.premium_card3 img:hover,
.product_content_img > img:hover,
.get_card:hover,
.btn_contact:hover,
.btn_get:hover ,
.rank_product_left a:hover,
.bank_content_right img:hover,
.header_bot img:hover,
.bank_card1:hover,
.bank_card2:hover,
.bank_card3:hover,
.bank_card4:hover,
.bank_card5:hover,
.bank_card6:hover {
  filter: brightness(1.2);
  transition: filter 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
}
/* end hover items */
</style>
